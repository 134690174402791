/* eslint-disable */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Aclaraciones</h1>

    <div>
      <h3 class="form-division">Información general</h3>

      <div class="invoice-add-item special-margin">
        <b-field
          class="invoice-add-secondary-item-four required-input"
          label-position="inside"
          :label="`Tipo de aclaración (Req)`"
          :type="{ 'is-danger': newClarificationErrors.TIPO }"
          :message="{
            'El tipo no es valido': newClarificationErrors.TIPO,
          }"
        >
          <b-select
            :placeholder="`Tipo de aclaración`"
            icon="numeric-9-circle-outline"
            required
            type="number"
            step="any"
            v-model="newClarification.TIPO"
            expanded
            :disabled="objectInformation"
          >
            <option value="" disabled>Seleccione una opción</option>
            <option value="I">Importe</option>
            <option value="D">Devolución</option>
          </b-select>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-four"
          label-position="inside"
          label="Fecha (Req)"
          :type="{ 'is-danger': newClarificationErrors.FECHA }"
          :message="{
            'La fecha no es valida': newClarificationErrors.FECHA,
          }"
        >
          <b-datepicker
            :show-week-number="true"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            placeholder="Fecha"
            icon="calendar-today"
            required
            v-model="newClarification.FECHA"
            trap-focus
            :disabled="objectInformation"
          >
          </b-datepicker>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-four"
          label-position="inside"
          label="Comentarios (Opc)"
        >
          <b-input
            placeholder="Comentarios"
            type="text"
            icon="magnify-plus-outline"
            v-model="newClarification.OBSERVA"
            :disabled="objectInformation"
          >
          </b-input>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-four required-input"
          label-position="inside"
          label="Concepto de descuento (Req)"
          :type="{ 'is-danger': newClarificationErrors.CVE_CONCP }"
        >
          <b-select
            :placeholder="`Folio de aclaración`"
            icon="numeric-9-circle-outline"
            required
            type="number"
            step="any"
            v-model="newClarification.CVE_CONCP"
            expanded
            :disabled="objectInformation"
          >
            <option value="" disabled>Seleccione una opción</option>
            <option
              v-for="discountConcept in discountConcepts"
              :value="discountConcept._id"
              :key="discountConcept.CONCEPTO"
            >
              {{ discountConcept.CONCEPTO }}
            </option>
          </b-select>
        </b-field>
      </div>

      <h3 class="form-division">Detalles en aclaración</h3>
      <b-table
        pagination-position="both"
        :data="newClarification.items"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="purchaseOrder"
        custom-row-key="RENGLON"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        class="purchase-order-table providers-list-global"
        scrollable
        default-sort="CONCEP_IE"
      >
        <template #empty>
          <div class="has-text-centered">No hay detalles en aclaración</div>
        </template>

        <template slot-scope="props">
          <b-table-column label="Concepto" field="CONCEP_IE" sortable>
            <b-input
              placeholder="Ejemplo: Pago de Luz"
              expanded
              v-model="props.row.CONCEP_IE"
              required
              :disabled="newClarification.TIPO === 'D'"
            ></b-input>
          </b-table-column>

          <b-table-column label="Descripción" field="DESCRIBEAR" sortable>
            <b-input
              placeholder="Ejemplo: Pago de enero"
              expanded
              v-model="props.row.DESCRIBEAR"
              :disabled="newClarification.TIPO === 'D'"
            ></b-input>
          </b-table-column>

          <b-table-column
            label="Presentación"
            field="PRESENTACIONES"
            sortable
            :visible="newClarification.TIPO === 'D'"
          >
            <b-field
              class="invoice-option-select"
              v-if="newClarification.TIPO === 'D'"
            >
              <b-select
                placeholder="Ejemplo: PIEZA"
                expanded
                v-model="props.row.PRESENTACION"
                disabled
              >
                <option value="" disabled>Presentaciones</option>
                <option value="UB" v-if="!props.row.article.UNIDAD_EMP">
                  Unidad de medida base
                </option>
                <option value="UB" v-if="props.row.article.UNIDAD_EMP">
                  {{
                    capitalizeString(props.row.article.UNIDAD_EMP.DESCRIP_MED)
                  }}
                </option>
                <option
                  v-for="singlePresentation in props.row.article.PRESENTACIONES"
                  :value="singlePresentation._id"
                  :key="singlePresentation._id"
                >
                  {{ singlePresentation.NOMBRE }}
                </option>
              </b-select>
            </b-field>
          </b-table-column>

          <b-table-column label="Costo" field="COSTO_UNI" sortable>
            <b-input
              placeholder="Ejemplo: 100"
              type="Number"
              step="any"
              expanded
              v-model="props.row.COSTO_UNI"
              required
              @input.native="updateTotal(props.row)"
              :disabled="newClarification.TIPO === 'D'"
            ></b-input>
          </b-table-column>

          <b-table-column
            label="Cantidad vendida"
            field="CANTIDAD_VENDIDA"
            sortable
            :visible="newClarification.TIPO === 'D' && !objectInformation"
          >
            <b-input
              placeholder="Ejemplo: 0"
              type="number"
              step="any"
              expanded
              :value="props.row.CANTIDAD_VENDIDA"
              disabled
            ></b-input>
          </b-table-column>

          <b-table-column
            :label="
              newClarification.TIPO === 'D' ? 'Cantidad a devolver' : 'Cantidad'
            "
            field="CANTIDAD"
            sortable
          >
            <b-input
              :disabled="newClarification.TIPO !== 'D' || objectInformation"
              placeholder="Ejemplo: 0"
              type="number"
              step="any"
              expanded
              v-model="props.row.CANTIDAD"
              @input.native="updateTotal(props.row)"
            ></b-input>
          </b-table-column>

          <b-table-column label="Porcentaje de IVA" field="PORC_IVA" sortable>
            <b-input
              placeholder="Ejemplo: 0"
              type="number"
              step="any"
              expanded
              v-model="props.row.PORC_IVA"
              @input.native="updateTotal(props.row)"
              :disabled="newClarification.TIPO === 'D'"
            />
          </b-table-column>

          <b-table-column label="Porcentaje de IEPS" field="PORC_IEPS" sortable>
            <b-input
              placeholder="Ejemplo: 0"
              type="number"
              step="any"
              expanded
              v-model="props.row.PORC_IEPS"
              @input.native="updateTotal(props.row)"
              :disabled="newClarification.TIPO === 'D'"
            />
          </b-table-column>

          <b-table-column label="Total" field="TOTAL" sortable>
            <b-input
              placeholder="Ejemplo: 0"
              type="number"
              step="any"
              expanded
              v-model="props.row.TOTAL"
              required
              :disabled="newClarification.TIPO === 'D'"
              @input.native="calculatePricesCreditNote(props.row)"
            ></b-input>
          </b-table-column>
        </template>
      </b-table>
    </div>

    <div class="invoice-payment-total-info-discounts">
      <div class="invoice-payment-total-left"></div>
      <div class="invoice-payment-total-rigth">
        <strong>Subtotal:</strong>
        <strong class="invoice-payment-total-money">{{
          this.newClarification.SUB_TOTAL | money("MXN", 2)
        }}</strong>
      </div>
    </div>

    <div class="invoice-payment-total-info-discounts">
      <div class="invoice-payment-total-left"></div>
      <div class="invoice-payment-total-rigth">
        <strong>IVA:</strong>
        <strong class="invoice-payment-total-money">{{
          this.newClarification.IVA_GTO | money("MXN", 2)
        }}</strong>
      </div>
    </div>

    <div class="invoice-payment-total-info-discounts">
      <div class="invoice-payment-total-left"></div>
      <div class="invoice-payment-total-rigth">
        <strong>IEPS:</strong>
        <strong class="invoice-payment-total-money">{{
          this.newClarification.IEPS_GTO | money("MXN", 2)
        }}</strong>
      </div>
    </div>

    <div class="invoice-payment-total-info">
      <div class="invoice-payment-total-left"></div>
      <div class="invoice-payment-total-rigth">
        <strong>Total:</strong>
        <strong class="invoice-payment-total-money">{{
          this.newClarification.TOTAL_GTO | money("MXN", 2)
        }}</strong>
      </div>
    </div>

    <div class="add-global-controls">
      <b-button
        type="is-success"
        @click="saveClarification()"
        v-if="!objectInformation"
        >Agregar aclaración</b-button
      >

      <b-button
        type="is-danger"
        @click="closeModal()"
        :class="{ 'cancel-button': !objectInformation }"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import { capitalizeFirstLetter } from "../../../utils/fns";
import {
  checkValidationErrors,
  round,
  validateFields,
  toFixedNoRound,
} from "../../../utils/fns";
import { fillObjectForm } from "../../../utils/component-fns";
export default {
  name: "AddClarificationModal",
  props: ["saleInformation", "objectInformation"],
  components: {},
  data() {
    return {
      // Table values
      newClarification: {
        FECHA: new Date(),
        OBSERVA: "",
        TIPO: "I",
        CVE_CONCP: "",
        SUB_TOTAL: 0,
        IVA_GTO: 0,
        IEPS_GTO: 0,
        IMPIMPTO1: 0,
        TOTAL_GTO: 0,
        STATUS: "AC",
        VENTA: this.saleInformation._id,
        EXENTO: 0,
        items: [
          {
            CONCEP_IE: "",
            RENGLON: 1,
            RENGLON_VENTA: -1,
            PORC_IVA: 16,
            PORC_IEPS: 0,
            COSTO_UNI: 0,
            IVA_GTO: 0,
            IEPS_GTO: 0,
            DESCRIBEAR: "",
            SUB_TOT: 0,
            TOTAL: 0,
            CANTIDAD: 1,
          },
        ],
      },
      newClarificationErrors: {
        TIPO: false,
        FECHA: false,
        CVE_CONCP: false,
      },
      moment: moment,
      perPage: 25,
      checkedWarehouses: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    // Fill the brand information in form fields
    if (this.objectInformation) {
      // Fill the form with received information
      fillObjectForm(this.objectInformation, this.newClarification);
      if (this.objectInformation.ACLAR_DET)
        this.newClarification.items = this.objectInformation.ACLAR_DET;
      if (this.objectInformation.FECHA)
        this.newClarification.FECHA = new Date(this.objectInformation.FECHA);
      if (this.objectInformation.CVE_CONCP)
        this.newClarification.CVE_CONCP = this.objectInformation.CVE_CONCP._id;

      for (const singleItem of this.newClarification.items) {
        singleItem.article = singleItem.CLAVE_ART;
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    capitalizeString(string) {
      return capitalizeFirstLetter(string);
    },
    async saveClarification() {
      try {
        this.articleRequiredErrors = validateFields(
          this.newClarification,
          this.newClarificationErrors
        );
        if (checkValidationErrors(this.newClarificationErrors)) {
          let response = await this.$store.dispatch("SAVECLARIFICATION", {
            clarification: {
              ...this.newClarification,
              items: this.checkInformation(this.newClarification),
            },
            saleId: this.saleInformation._id,
          });
          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente la clarificación",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar la clarificación en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la clarificación en la base de datos revise los campos requeridos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardarla clarificación en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    getIva(item) {
      if (item.article) {
        if (
          item.article.CANT_IVA !== undefined &&
          item.article.CANT_IVA !== null
        ) {
          return item.article.CANT_IVA;
        } else {
          return 16;
        }
      }
      return 0;
    },
    getIeps(item) {
      if (
        item.article.CANT_IEPS !== undefined &&
        item.article.CANT_IEPS !== null
      ) {
        return item.article.CANT_IEPS;
      }
      return 0;
    },
    updateTotal(expenseInformation, changeTotal = true) {
      if (this.clarificationTypeValue === "I") {
        let ivaTotal =
          expenseInformation.COSTO_UNI *
          expenseInformation.CANTIDAD *
          (expenseInformation.PORC_IVA / 100);
        let iepsTotal =
          expenseInformation.COSTO_UNI *
          expenseInformation.CANTIDAD *
          (expenseInformation.PORC_IEPS / 100);
        let total =
          Number(expenseInformation.COSTO_UNI) * expenseInformation.CANTIDAD +
          Number(ivaTotal) +
          Number(iepsTotal);
        expenseInformation.SUB_TOT =
          expenseInformation.COSTO_UNI * expenseInformation.CANTIDAD;
        expenseInformation.IVA_GTO = ivaTotal;
        expenseInformation.IEPS_GTO = iepsTotal;
        if (changeTotal) {
          expenseInformation.TOTAL = total;
        }
        this.newClarification.SUB_TOTAL = round(
          parseFloat(
            expenseInformation.COSTO_UNI * expenseInformation.CANTIDAD
          ).toFixed(5),
          2
        );
        this.newClarification.IVA_GTO = round(
          parseFloat(ivaTotal).toFixed(5),
          2
        );
        this.newClarification.IEPS_GTO = round(
          parseFloat(iepsTotal).toFixed(5),
          2
        );
        this.newClarification.IMPIMPTO1 = round(
          parseFloat(
            expenseInformation.IVA_GTO + expenseInformation.IEPS_GTO
          ).toFixed(5),
          2
        );
        this.newClarification.TOTAL_GTO = round(
          parseFloat(total).toFixed(5),
          2
        );
        this.newClarification.EXENTO = round(parseFloat(0).toFixed(5), 2);
      } else if (this.clarificationTypeValue === "D") {
        let total = 0;
        let subtotal = 0;
        let iva = 0;
        let ieps = 0;
        let excent = 0;
        for (const [
          singleItemIndex,
          singleItem,
        ] of this.newClarification.items.entries()) {
          if (singleItem.article) {
            let ivaTotal = 0;
            let iepsTotal = 0;
            let priceQuantity = singleItem.COSTO_UNI * singleItem.CANTIDAD;

            let totalItem = 0;
            totalItem += priceQuantity;
            subtotal += priceQuantity;

            // Calculate iva quantity
            let itemIva = priceQuantity * (singleItem.PORC_IVA / 100);
            totalItem += itemIva;
            ivaTotal += itemIva;
            iva += itemIva;

            // Calculate ieps quantity
            let itemIeps = priceQuantity * (singleItem.PORC_IEPS / 100);
            totalItem += itemIeps;
            iepsTotal += itemIeps;
            ieps += itemIeps;

            // Add iva excent to excent total
            if (singleItem.article && singleItem.article.TIPO_IVA === "EX") {
              excent += priceQuantity;
            }

            // set items values to the ones needed
            this.newClarification.items[singleItemIndex].SUB_TOT =
              priceQuantity;
            this.newClarification.items[singleItemIndex].IVA_GTO = ivaTotal;
            this.newClarification.items[singleItemIndex].IEPS_GTO = iepsTotal;
            this.newClarification.items[singleItemIndex].TOTAL = totalItem;
          }
        }

        // Update total with iva and ieps
        total += subtotal + iva + ieps;

        // Set aclarations values to the ones needed
        this.newClarification.SUB_TOTAL = round(
          parseFloat(subtotal).toFixed(5),
          2
        );
        this.newClarification.IVA_GTO = round(parseFloat(iva).toFixed(5), 2);
        this.newClarification.IEPS_GTO = round(parseFloat(ieps).toFixed(5), 2);
        this.newClarification.IMPIMPTO1 = round(
          parseFloat(iva + ieps).toFixed(5),
          2
        );
        this.newClarification.TOTAL_GTO = round(
          parseFloat(total).toFixed(5),
          2
        );
        this.newClarification.EXENTO = round(parseFloat(excent).toFixed(5), 2);
      }
    },
    /**
     *
     * @desc checks all items in the aclaration and return only the ones with more than 1 in quantity
     */
    checkInformation(newClarification) {
      const filteredItems = [...newClarification.items]
        .map((singleItem) => {
          const singleItemWithProps = { ...singleItem };
          if (singleItem.article) {
            singleItemWithProps.CLAVE_ART = singleItem.article._id;
          }
          return singleItemWithProps;
        })
        .filter((singleItem) => {
          return (
            singleItem.CONCEP_IE &&
            singleItem.COSTO_UNI &&
            singleItem.TOTAL &&
            Number(singleItem.CANTIDAD) > 0
          );
        });
      return filteredItems;
    },
    calculatePricesCreditNote(rowValues) {
      if (this.clarificationTypeValue === "I") {
        for (const [
          singleItemIndex,
          singleItem,
        ] of this.newClarification.items.entries()) {
          if (singleItem.RENGLON === rowValues.RENGLON) {
            this.newClarification.items[singleItemIndex].COSTO_UNI =
              toFixedNoRound(
                rowValues.TOTAL / (1 + rowValues.PORC_IVA / 100),
                5
              );
          }
        }
        this.updateTotal(rowValues, false);
      }
    },
  },
  computed: {
    lastClarificationNumber() {
      return this.$store.getters.LASTCLARIFICATIONNUMBER;
    },
    lastCreditNoteNumber() {
      return this.$store.getters.LASTCREDITNOTENUMBER;
    },
    discountConcepts() {
      return this.$store.getters.DISCOUNTCONCEPTS.map(
        (singleDiscountConcepts) => {
          const discountConceptsWithProps = { ...singleDiscountConcepts };
          return discountConceptsWithProps;
        }
      );
    },
    clarificationTypeValue() {
      return this.newClarification.TIPO;
    },
  },
  watch: {
    /**
     * @desc Select default opened details, to open a specific warehouse
     */
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    clarificationTypeValue() {
      if (this.clarificationTypeValue === "I") {
        this.newClarification.SUB_TOTAL = 0;
        this.newClarification.IVA_GTO = 0;
        this.newClarification.IEPS_GTO = 0;
        this.newClarification.IMPIMPTO1 = 0;
        this.newClarification.TOTAL_GTO = 0;
        this.newClarification.items = [
          {
            CONCEP_IE: "",
            RENGLON: 1,
            RENGLON_VENTA: -1,
            PORC_IVA: 16,
            PORC_IEPS: 0,
            COSTO_UNI: 0,
            IVA_GTO: 0,
            IEPS_GTO: 0,
            DESCRIBEAR: "",
            SUB_TOT: 0,
            TOTAL: 0,
            CANTIDAD: 1,
            EXENTO: "N",
          },
        ];
      } else if (this.clarificationTypeValue === "D") {
        const saleItems = [];
        for (const [
          singleItemIndex,
          singleItem,
        ] of this.saleInformation.currentItems.entries()) {
          saleItems.push({
            CONCEP_IE: singleItem.article ? singleItem.article.CLAVE_ART : "",
            RENGLON: singleItemIndex + 1,
            RENGLON_VENTA: singleItem.RENGLON,
            PORC_IVA: this.getIva(singleItem),
            PORC_IEPS: this.getIeps(singleItem),
            COSTO_UNI: singleItem.price,
            CANTIDAD_VENDIDA: singleItem.quantity,
            IVA_GTO: 0,
            IEPS_GTO: 0,
            DESCRIBEAR: singleItem.article
              ? singleItem.article.DESCRIPCION
              : "",
            SUB_TOT: 0,
            TOTAL: 0,
            CANTIDAD: 1,
            article: singleItem.article,
            CLAVE_DETALLE: singleItem._id,
            PRESENTACION: singleItem.presentation,
            CANTIDAD_PRESENTACION: singleItem.CANTIDAD_PRESENTACION,
            EXENTO: singleItem.EXENTO,
          });
        }
        this.newClarification.items = saleItems;
        this.updateTotal();
      }
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
